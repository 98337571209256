<template>
	<PopupTwoCol name="melangeuse"
	text="<h3>La mélangeuse</h3><br/><p>C’est une installation qui permet d’assembler les produits semi-finis sortant des unités de raffinage pour en faire un produit commercial répondant à des normes strictes.</p><br/><p>Par exemple, le Sans Plomb 95 est constitué :</p><p>- des bases essences désulfurées produites par les unités suivantes : unité d’hydrotraitement des essences, réformeur régénératif, PrimeG, alkylation ;</p><p>- de butane ;</p><p>- de biocarburant (importé par la raffinerie).</p>">
	<picture slot="thumbnail__1">
		<img class="lazy"
		sizes="(max-width: 970px) 100vw, 970px"
		data-srcset="
		img/popup/milkshake_à_la_fraise_fzmi67_c_scale_w_350.png 350w,
		img/popup/milkshake_à_la_fraise_fzmi67_c_scale_w_970.png 970w"
		data-src="img/popup/milkshake_à_la_fraise_fzmi67_c_scale_w_970.png"
		src="static/logo-total.jpg"
		alt="">
	</picture>
</PopupTwoCol>
</template>

<script>
	export default {
		components: {
			PopupTwoCol: () => import('@/components/PopupTwoCol.vue'),
		},
	}
</script>
